/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  DateRangePicker as RawComp,
  SpectrumDateRangePickerProps,
} from '@adobe/react-spectrum';
import { enUSLocaleFormat } from '@components/DatepickerV2';
import { Digit } from '@components/Digit';
import { Label } from '@components/FieldLabel';
import { Grid } from '@components/Grid';
import { VisuallyHidden } from '@components/VisuallyHidden';
import { Global } from '@emotion/react';
import { useFlagMe326512DatePickerDismissOnShortcutKey } from '@generated/flags/ME-326512-date-picker-dismiss-on-shortcut-key';
import { useEffectAfterMount } from '@hooks/useEffectAfterMount';
import { useTheme } from '@hooks/useTheme';
import {
  ZonedDateTime,
  isEqualDay,
  isSameDay,
  isSameMonth,
  now,
  parseAbsolute,
} from '@internationalized/date';
import { IANATimezones, currentTZ } from '@utils/time';
import { startOfDay } from '@utils/time/util';
import cx from 'classnames';
import { isPlainObject, isString, noop, take } from 'lodash-es';
import { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useLocale } from 'react-aria';
import { useMount } from 'react-use';
import CalendarSVG from '../Icon/Calendar.svg';
import { DateFieldRange } from './DateFieldRange';
import { DateType } from './defs';
import flagOutlineActive from './img/flagOutlineActive.svg';
import flagOutlineMuted from './img/flagOutlineMuted.svg';
import {
  RelativeString,
  RelativeUnit,
  parseRelative,
  parseSingleDateRelative,
  relativeNamedMap,
  relativeStringToObj,
  relativeToString,
} from './util/relative';

export interface OnChangeArg {
  start: Date | null;
  end: Date | null;
  relative: RelativeString | null;
}

export interface Value {
  start: Date | string | null;
  end: Date | string | null;
  relative: RelativeString | null;
}

interface SingleDatePickerValue {
  value: Date | string | null;
  relative: RelativeString | null;
}

const dateisValidFormat = (value: string | Date): boolean => {
  if (typeof value === 'string') {
    // stringIsISODateFormat
    return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(value);
  } else if (value instanceof Date) {
    // Check if it's a valid Date object
    return !isNaN(value.getTime());
  }
  return false;
};

// ts-unused-exports:disable-next-line
export const isDateRangePickerShape = (value: anyOk): value is Value => {
  return (
    isPlainObject(value) &&
    value.start &&
    value.end &&
    dateisValidFormat(value.start) &&
    dateisValidFormat(value.end)
  );
};

// ts-unused-exports:disable-next-line
export const isDatePickerShape = (
  value: anyOk
): value is SingleDatePickerValue => {
  return isPlainObject(value) && value.value && dateisValidFormat(value.value);
};

// ts-unused-exports:disable-next-line
export const isDatePickerShapeHasRelative = (value: anyOk): value is Value => {
  const evaluate = isDatePickerShape(value) && Boolean(value.relative);
  return evaluate;
};

// ts-unused-exports:disable-next-line
export const isDateRangePickerShapeHasRelative = (
  value: anyOk
): value is Value => {
  return isDateRangePickerShape(value) && Boolean(value.relative);
};

enum DateChangeType {
  DayChanged = 'dayChanged',
  MonthChanged = 'monthChanged',
  NoChange = 'noChange',
  InvalidInput = 'invalidInput',
}

const determineDateChange = (
  date1: ZonedDateTime | undefined,
  date2: ZonedDateTime | undefined
): DateChangeType => {
  if (!date1 || !date2) {
    return DateChangeType.InvalidInput;
  }

  if (!isSameMonth(date1, date2)) {
    return DateChangeType.MonthChanged;
  }
  if (!isSameDay(date1, date2)) {
    return DateChangeType.DayChanged;
  }

  return DateChangeType.NoChange;
};

const findFocusElement = (
  date1: DateChangeType,
  date2: DateChangeType
): string => {
  const isRelevantChange = (date: DateChangeType): boolean =>
    date !== DateChangeType.InvalidInput && date !== DateChangeType.NoChange;

  const title = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  if (isRelevantChange(date1)) {
    return `start${title(date1)}`;
  }

  if (isRelevantChange(date2)) {
    return `end${title(date2)}`;
  }

  return '';
};

export const convertDatePickerShape = <T extends unknown>(value: T): T => {
  let newValue = value;
  if (isDateRangePickerShapeHasRelative(value)) {
    const parseResult =
      parseRelative((value as anyOk).relative as string) ?? [];
    const [start, end] = parseResult;
    if (start && end) {
      newValue = {
        start,
        end,
        relative: value.relative,
      } as T;
    }
  } else if (isDateRangePickerShape(value)) {
    newValue = {
      start: new Date(value.start as string),
      end: new Date(value.end as string),
      relative: null,
    } as T;
  } else if (isDatePickerShapeHasRelative(value)) {
    const parseSingleDatePickerResult =
      parseSingleDateRelative((value as anyOk).relative as string) ?? [];
    const val = parseSingleDatePickerResult;
    if (val) {
      newValue = {
        value: val,
        relative: value.relative,
      } as T;
    }
  } else if (isDatePickerShape(value)) {
    newValue = {
      value: new Date(value.value as string),
      relative: null,
    } as T;
  }
  return newValue;
};

export interface Props
  extends Partial<
      Omit<
        SpectrumDateRangePickerProps<fixMe>,
        | 'onChange'
        | 'start'
        | 'end'
        | 'value'
        | 'hourCycle'
        | 'isOpen'
        | 'isReadOnly'
        | 'onBlur'
      >
    >,
    Value {
  timezone: IANATimezones;
  onChange: (kwargs: OnChangeArg) => void;
  /** Show years on the picker. This prop should not be used per product decision, unless the user needs 6+ month time ranges, ie accounting. Defaults to false. */
  showYears?: boolean;
  /** Specify the exact relative options to show. If this prop not defined, all options will show */
  relativeOptionsEnabled?: RelativeUnit[];
  readOnly?: boolean;
  /** Put the input inside of the dialog. Probably only used for chip filter implementations. */
  inlineInput?: boolean;
  /** When the value is null (User has only partial input OR the values are completely cleared) - coerce to a specific range when the user exits (blurs) the input and call onChange callback. Commonly used if the control needs to always have a range present. */
  coerceNullValue?: () => Value;
  fsSearchElement?: string;
}

const keyboardMapping: Record<string, RelativeUnit> = {
  t: 'T',
  m: 'M',
  y: 'Y',
  w: 'TW',
  p: 'LW',
  x: 'TM',
  z: 'LM',
  a: 'L30D',
  n: 'N',
  u: 'U',
  b: 'B',
  c: '31-60D',
  d: '61-90D',
  e: '91-180D',
};

const getKeyboardFromRelative = (
  base: RelativeUnit | null
): string | undefined => {
  return Object.entries(keyboardMapping).find(([, s]) => s === base)?.[0];
};

interface FitBoundsProps {
  start?: DateType;
  end?: DateType;
  timezone: IANATimezones;
  computedValue: {
    start?: ZonedDateTime;
    end?: ZonedDateTime;
    value?: ZonedDateTime;
  } | null;
  showYears?: boolean;
  isOpen?: boolean;
  key?: 'start' | 'value';
}

const fitBounds = (
  args: FitBoundsProps
): Pick<FitBoundsProps, 'start' | 'end'> => {
  const {
    start,
    end,
    timezone,
    computedValue,
    showYears,
    isOpen,
    key = 'start',
  } = args;
  let startVal = start;
  let endVal = end;

  if (!startVal || !endVal || showYears) {
    return { start: startVal, end: endVal };
  }

  const noopDate = parseAbsolute(new Date(1).toISOString(), 'UTC');

  const startChanged =
    key === 'start'
      ? !isEqualDay(computedValue?.start ?? noopDate, startVal)
      : !isEqualDay(computedValue?.value ?? noopDate, startVal);

  // If the user picked from the calendar, (did not use the input with keyboard), we opt out of most coercions because they have chosen a very specific date by paging through the months.
  const pickedFromCalendar = isOpen;
  const cutoffNegative = now(timezone).subtract({ months: 3, days: 1 });
  const cutoffPositive = cutoffNegative.add({ years: 1 });

  if (!showYears && !pickedFromCalendar) {
    if (startVal.compare(cutoffNegative) < 0) {
      startVal = startVal.add({ years: 1 });
    } else if (startVal.compare(cutoffPositive) > 0) {
      startVal = startVal.subtract({ years: 1 });
    }

    if (endVal.compare(cutoffNegative) < 0) {
      endVal = endVal.add({ years: 1 });
    } else if (endVal.compare(cutoffPositive) > 0) {
      endVal = endVal.subtract({ years: 1 });
    }

    if (startChanged && !pickedFromCalendar) {
      endVal = startVal;
    }
  }

  const endLessThanStart = endVal.compare(startVal) < 0;
  if (endLessThanStart && !showYears) {
    endVal = startVal;
  }
  return { start: startVal, end: endVal };
};

export const attemptVisualCalendarForcedUpdate = (kwargs: {
  isNeg: boolean;
  incKey: () => void;
  noRemount?: boolean;
  focusDateField?: { visible: boolean; focusElement: string };
}): void => {
  const { isNeg, incKey, noRemount } = kwargs;
  // React Spectrum has a bug in which the calendar does not reflect the value automatically
  // https://github.com/adobe/react-spectrum/issues/3408
  // This timeout attempts to click the previous or next button to make the calendar update
  setTimeout(() => {
    const found = document.querySelector(
      '[class*="spectrum-Calendar"] [aria-selected="true"]'
    );
    if (!found) {
      const actionBtn = document.querySelector(
        `[class*="spectrum-Calendar-${isNeg ? 'prev' : 'next'}Month"]`
      ) as Maybe<HTMLButtonElement>;
      actionBtn?.click();
      if (kwargs?.focusDateField?.visible) {
        focusDateField(kwargs?.focusDateField?.focusElement);
      }
      // If for some reason the calendar does not reflect what it should, we do a last ditch fix here to remount the component
      // You can trigger this scenario by viewing a month a few months out, then clicking a relative selection like yesterday.
      if (!noRemount) {
        setTimeout(() => {
          const found2 = document.querySelector(
            '[class*="spectrum-Calendar"] [aria-selected="true"]'
          );
          if (!found2) {
            incKey();
            if (kwargs?.focusDateField?.visible) {
              focusDateField(kwargs?.focusDateField?.focusElement);
            }
          }
        }, 30);
      }
    }
  }, 30);
};

const focusDateField = (focusElement?: string): void => {
  // Container that holds both day and month elements
  const container = document.querySelectorAll(
    'div[id^="react-aria"][aria-label="Date"]'
  ) as NodeListOf<HTMLDivElement>;

  const spinbuttonElements = focusElement?.includes('start')
    ? container[0]?.querySelectorAll('div[role="spinbutton"]')
    : container[1]?.querySelectorAll('div[role="spinbutton"]');

  // Find day element by checking for valid min and max values
  // Max days are 28, 29, 30, 31
  const dayElement = Array.from(spinbuttonElements ?? []).find(
    (el) =>
      el.getAttribute('aria-valuemin') === '1' &&
      el.getAttribute('aria-valuemax') !== null &&
      ['28', '29', '30', '31'].includes(el.getAttribute('aria-valuemax') ?? '')
  ) as Maybe<HTMLButtonElement>;

  const monthElement = Array.from(spinbuttonElements ?? []).find(
    (el) =>
      el.getAttribute('aria-valuemin') === '1' &&
      el.getAttribute('aria-valuemax') === '12'
  ) as Maybe<HTMLButtonElement>;

  if (!focusElement) {
    // Default to the start date month field
    monthElement?.focus();
    return;
  }

  focusElement?.includes('DayChanged')
    ? dayElement?.focus()
    : monthElement?.focus();
};

interface InternalState {
  start: Date | null;
  end: Date | null;
  minus: number;
  plus: number;
  relativeBase: RelativeUnit | null;
}

const coerceToInternalStateFromProps = (
  props: Pick<Props, 'start' | 'end' | 'relative'>,
  timezone: IANATimezones = currentTZ
): InternalState => {
  const { start, end, relative } = props;
  const stringStart = isString(start) ? start : start?.toISOString();
  const stringEnd = isString(end) ? end : end?.toISOString();
  let coercedStart = stringStart;
  let coercedEnd = stringEnd;
  let coercedRelativeBase = null;
  const obj = relativeStringToObj(relative as unknown as string);
  if (relative) {
    const map = relativeNamedMap[(obj?.base ?? '') as RelativeUnit];
    coercedStart = map
      .startFn(timezone ?? currentTZ)
      .add({ days: obj?.start.days ?? 0 })
      .toDate()
      .toISOString();
    coercedEnd = map
      .endFn(timezone ?? currentTZ)
      .add({ days: obj?.end.days ?? 0 })
      .toDate()
      .toISOString();
    coercedRelativeBase = obj?.base ?? null;
  }
  return {
    start: coercedStart ? new Date(coercedStart) : null,
    end: coercedEnd ? new Date(coercedEnd) : null,
    minus: Math.abs(obj?.start.days ?? 0),
    plus: obj?.end.days ?? 0,
    relativeBase: coercedRelativeBase,
  };
};

const areInternalStatesEqual = (
  state1: InternalState,
  state2: InternalState
): boolean => {
  return (
    state1.start?.valueOf() === state2.start?.valueOf() &&
    state1.end?.valueOf() === state2.end?.valueOf() &&
    state1.minus === state2.minus &&
    state1.plus === state2.plus &&
    state1.relativeBase === state2.relativeBase
  );
};

const coerceToStartOfDay = (d: Maybe<ZonedDateTime>): ZonedDateTime | null => {
  if (!d) {
    return null;
  }
  return startOfDay(d);
};

export const DateRangePicker: FC<Props> = (props) => {
  const {
    start,
    end,
    relative = '',
    timezone,
    onChange,
    showYears: showYearsProp = false,
    relativeOptionsEnabled = take(
      Object.keys(relativeNamedMap),
      11
    ) as RelativeUnit[],
    readOnly,
    inlineInput,
    coerceNullValue,
    ...spreadProps
  } = props;
  const [internalIsOpen, setInternalIsOpen] = useState(
    spreadProps.defaultOpen ?? false
  );
  const closeOnShortcutKey = useFlagMe326512DatePickerDismissOnShortcutKey();
  const { locale } = useLocale();
  const isLocaleEnUSFormat = enUSLocaleFormat.has(locale);
  const hasMountedRef = useRef(false);
  const [showYears, setShowYears] = useState(showYearsProp);
  const [key, setKey] = useState(() => Date.now());
  const incKey = useCallback(() => setKey(Date.now()), []);
  const { colors, gray, formElement, fonts } = useTheme();
  const borderStyle = `1px solid ${gray[90]}`;
  const baseGray = gray[80];
  const [internalState, setInternalState] = useState<InternalState>(() => {
    return coerceToInternalStateFromProps(props, timezone);
  });

  useMount(() => {
    hasMountedRef.current = true;
  });

  useEffect(() => {
    const coerced = coerceToInternalStateFromProps(props, timezone);
    if (!areInternalStatesEqual(internalState, coerced)) {
      setInternalState(coerced);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end, relative]);

  useEffectAfterMount(() => {
    const onChangeArg = {
      start: internalState.start,
      end: internalState?.end,
      relative: (internalState.relativeBase
        ? relativeToString({
            base: internalState.relativeBase,
            start: { days: internalState.minus * -1 },
            end: { days: internalState.plus },
          }) ?? null
        : null) as Props['relative'],
    };
    onChange(onChangeArg);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalState]);

  const setRelative = useCallback(
    (key: RelativeUnit): void => {
      const obj = relativeNamedMap[key];
      setInternalState((state) => {
        const newStart = obj
          .startFn(timezone ?? currentTZ)
          .subtract({ days: internalState.minus })
          .toDate();
        const isNeg = (state.start?.valueOf() ?? 0) > (newStart.valueOf() ?? 0);
        attemptVisualCalendarForcedUpdate({ isNeg, incKey });
        return {
          start: newStart,
          end: obj
            .endFn(timezone ?? currentTZ)
            .add({ days: internalState.plus })
            .toDate(),
          relativeBase: key as unknown as RelativeUnit,
          minus: state.minus,
          plus: state.plus,
        };
      });
    },
    [incKey, internalState, timezone]
  );

  const computedValue =
    internalState.start && internalState.end
      ? {
          start: parseAbsolute(internalState.start.toISOString(), timezone),
          end: parseAbsolute(internalState.end.toISOString(), timezone),
        }
      : null;

  return (
    <div
      data-testid="component-daterangepicker"
      data-timezone={timezone}
      css={{
        ...(inlineInput && {
          height: 0,
          width: 0,
          lineHeight: 0,
          overflow: 'hidden',
          position: 'relative',
          top: -32,
        }),
        '[data-testid="year"]': {
          display: showYears ? 'block' : 'none',
        },
        // modify the show/hide the '/' based on MM/DD/YYYY or DD/MM/YYYY
        '[data-testid="year"] + span': {
          display: showYears ? 'block' : isLocaleEnUSFormat ? 'none' : 'block',
        },
        // modify the show/hide the '/' based on MM/DD/YYYY or DD/MM/YYYY
        '[data-testid="day"] + span': {
          display: showYears ? 'block' : isLocaleEnUSFormat ? 'none' : 'block',
        },
        // modify the show/hide the '/' based on MM/DD/YYYY or DD/MM/YYYY
        '[data-testid="month"] + span': {
          display: showYears ? 'block' : isLocaleEnUSFormat ? 'block' : 'none',
        },
        '[class*="spectrum-FieldButton"]::before': {
          borderColor: `${baseGray} !important`,
          borderLeftWidth: '0px !important',
        },
        '[class*="react-spectrum-Datepicker-fieldWrapper"]': inlineInput && {
          width: '1px !important',
          minWidth: '0 !important',
          height: 1,
          opacity: 0,
          pointerEvents: 'none',
          overflow: 'hidden',
        },
        '[class*="react-spectrum-Datepicker-fieldWrapper"]#date-field-inline-input':
          {
            width: 'auto !important',
            minWidth: 'auto !important',
            height: 'auto',
            opacity: 1,
            pointerEvents: 'auto',
          },
        '[class*="spectrum-Textfield-input"]': {
          border: `1px solid ${baseGray} !important`,
          paddingTop: '6px !important',
          paddingRight: '1px !important',
          paddingLeft: '1px !important',
          fontSize: '12px !important',
        },
        '[class*=spectrum-Textfield-input]': {
          background: readOnly ? formElement.readOnly.background : undefined,
        },
        '[class*=spectrum-Textfield-input] *': {
          fontFamily: `${fonts.main} !important`,
          fontStyle: 'normal !important',
        },
        '[class*=react-spectrum-Datepicker-inputSized]': {
          minWidth: '17ch !important',
          paddingLeft: 6,
        },
        '[class*=react-spectrum-Datepicker-fieldWrapper]:has([data-testid="date-range-dash"])':
          {
            minWidth: '147px !important',
            '&.show-years': {
              minWidth: '218px !important',
            },
          },
        '[class*=react-spectrum-Datepicker-endField]': {
          paddingInlineStart: 'unset !important',
        },
        '[class*=spectrum-Icon] > *': {
          display: 'none',
        },
        '[class*=spectrum-Icon]': {
          backgroundImage: `url(${CalendarSVG})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
        },
        '[class*="spectrum-FieldButton"]': {
          alignItems: 'center',
          width: '24px !important',
          cursor: 'pointer',
        },
      }}
      onKeyUp={(e): void => {
        if (e.key === 'q') {
          return setShowYears((t) => !t);
        }
        const match = keyboardMapping[e.key.toLowerCase()];
        if (match) {
          setRelative(match);
          (
            document.querySelector(
              `[data-relative=${match}]`
            ) as Maybe<HTMLButtonElement>
          )?.focus();

          // Dismiss the dialog if user types the shortcut (do not dismiss if clicked).
          if (closeOnShortcutKey) {
            // Clear +/- inputs on keypress.
            setInternalState((s) => ({
              ...s,
              plus: 0,
              minus: 0,
            }));
            setInternalIsOpen(false);
            spreadProps.onOpenChange?.(false);
          }
        }
      }}
    >
      <Global
        styles={{
          '[class*=spectrum-Dialog][class*=react-spectrum-Datepicker-dialog]': {
            minWidth: '300px !important',
          },
          '[class*="react-spectrum-Datepicker-dialog"] [class*="spectrum-Dialog-grid"]':
            {
              gridTemplateColumns: 'auto !important',
              gridTemplateRows: 'auto !important',
              display: 'block !important',
              padding: 16,
            },
          '[class*="react-spectrum-Datepicker-dialogContent"]:has([data-relative-selection])':
            {
              display: 'grid !important',
              gridTemplateColumns: 'min-content 1fr',
            },
          '#date-field-inline-input [data-testid="year"]': {
            display: 'none',
          },
          '#date-field-inline-input [data-testid="day"] + span': {
            display: 'none',
          },
          '[class*=react-spectrum-Datepicker-timeFields]': {
            gridColumnStart: 2,
          },
          '[class*=spectrum-Popover]': {
            transition: 'none !important',
          },
          '[class*="spectrum-Icon"]': {
            paddingRight: '0 !important',
            height: '14px !important',
            width: '14px !important',
          },
          '[class*="spectrum-Calendar-months"]': {
            paddingBottom: 10,
          },
          '[class*=react-spectrum-Datepicker-calendar][class*=is-invalid]': {
            display: 'block !important',
          },
          '[class*=spectrum-InputGroup--invalid]': {
            '[aria-label="Calendar"] svg': {
              display: 'none',
            },
            '[class*="spectrum-UIIcon-AlertMedium"]': {
              right: '-25px !important',
              zIndex: 5,
              pointerEvents: 'none',
            },
          },
          '[class*="spectrum-Calendar"] [role=button]:focus-visible': {
            outline: '0 !important',
          },
          '[class*=react-spectrum-DatePicker-placeholder]': {
            fontFamily: `${fonts.main} !important`,
            fontStyle: 'normal !important',
          },
        }}
      />
      <RawComp
        key={key}
        granularity="day"
        {...spreadProps}
        UNSAFE_className={cx(
          spreadProps.UNSAFE_className,
          props.showYears && 'show-years'
        )}
        isReadOnly={readOnly}
        shouldForceLeadingZeros
        // without this noop, we get error text rendered below the control, which we don't want.
        errorMessage={noop}
        {...{ ['aria-label']: 'Date Range' }}
        onOpenChange={(isOpen): void => {
          setInternalIsOpen(isOpen);
          spreadProps.onOpenChange?.(isOpen);
        }}
        // Spectrum has a TS bug that doesn't allow null
        // https://github.com/adobe/react-spectrum/issues/3187
        value={computedValue ? computedValue : (null as anyOk)}
        hourCycle={24}
        isDateUnavailable={(): boolean => {
          return false;
          // We may want to uncomment the below code if we want to limit what dates the user can pick from the calendar dialog.
          // if (showYears) {
          //   return false;
          // }
          // const now = Date.now();
          // return !inRange(
          //   val.toDate(timezone).valueOf(),
          //   now - twoMonthsMs - dayMs,
          //   now + sixMonthsMs + dayMs
          // );
        }}
        onChange={(args): void => {
          // Both of these conditions prevent the onChange from running when the underlying spectrum component mounts. This causes unexpected sync behavior.
          if (!hasMountedRef.current) {
            return;
          } else if (Date.now() - key < 150) {
            return;
          }
          if (args == null) {
            return setInternalState(() => ({
              start: null,
              end: null,
              plus: 0,
              minus: 0,
              relativeBase: null,
            }));
          }
          const { start, end } = fitBounds({
            ...args,
            timezone,
            computedValue,
            showYears,
            isOpen: internalIsOpen,
          } as unknown as FitBoundsProps);
          setInternalState((s) => ({
            start: coerceToStartOfDay(start)?.toDate() ?? s?.start,
            end: coerceToStartOfDay(end)?.toDate() ?? s?.end,
            plus: 0,
            minus: 0,
            relativeBase: null,
          }));
        }}
        isOpen={internalIsOpen}
        onBlur={() => {
          // Since we handle the null onChange event a bit different, we need to detect when the user blurs and force a remount of the datepicker - this clears out any "bad" values such as partial dates
          if (
            !internalState.start &&
            !internalState.end &&
            !internalState.relativeBase
          ) {
            if (coerceNullValue) {
              const newVal = coerceNullValue?.();
              setInternalState(coerceToInternalStateFromProps(newVal));
            }
            incKey();
          }
        }}
        {...{
          extraDialogContent: (): ReactNode => (
            <>
              {inlineInput && (
                <>
                  <div
                    css={{
                      gridColumn: 'span 2',
                      paddingBottom: 16,
                    }}
                  >
                    <DateFieldRange
                      fsSearchElement={props.fsSearchElement}
                      {...spreadProps}
                      value={computedValue}
                      onChange={(val): void => {
                        // For some reason the DateFieldRange fires off onChange when it mounts. We don't want that here.
                        if (!hasMountedRef.current) {
                          return;
                        }
                        const zonedInternalStateStart = internalState?.start
                          ? parseAbsolute(
                              internalState.start?.toISOString(),
                              timezone
                            )
                          : null;

                        const zonedInternalStateEnd = internalState?.end
                          ? parseAbsolute(
                              internalState.end?.toISOString(),
                              timezone
                            )
                          : null;

                        const newStart =
                          val.start ??
                          zonedInternalStateStart ??
                          computedValue?.start;

                        const newEnd =
                          val.end ??
                          zonedInternalStateEnd ??
                          computedValue?.start;

                        const { start, end } = fitBounds({
                          start: newStart,
                          end: newEnd,
                          timezone,
                          computedValue,
                          showYears,
                          isOpen: internalIsOpen,
                        });

                        const startDetermineDateChange = determineDateChange(
                          start,
                          computedValue?.start
                        );
                        const endDetermineDateChange = determineDateChange(
                          end,
                          computedValue?.end
                        );

                        const isNeg =
                          (internalState.start?.valueOf() ?? 0) >
                          (start?.toDate()?.valueOf() ?? 0);

                        if (start && end) {
                          attemptVisualCalendarForcedUpdate({
                            isNeg,
                            incKey,
                            noRemount: false,
                            focusDateField: {
                              visible: true,
                              focusElement: findFocusElement(
                                startDetermineDateChange,
                                endDetermineDateChange
                              ),
                            },
                          });
                        }
                        setInternalState((s) => ({
                          ...s,
                          start: start?.toDate() ?? s.start,
                          end: end?.toDate() ?? s.end,
                          plus: 0,
                          minus: 0,
                          relativeBase: null,
                        }));
                      }}
                    />
                  </div>
                </>
              )}
              <div
                css={{
                  paddingRight: 10,
                  margin: '0 10px 0 0',
                  width: 130,
                  button: { textAlign: 'left', display: 'block' },
                  borderRight: borderStyle,
                }}
                // this helps this component work with chip filters. without this line, when user picks a relative option, the chip filter will close and the value will not be set
                onMouseDown={(e): void => e.stopPropagation()}
              >
                {relativeOptionsEnabled.length >= 12 ? (
                  <div>
                    Max 11 relative options are allowed. Please remove some
                    options.
                  </div>
                ) : (
                  <div css={{ display: 'grid' }} data-relative-selection>
                    {relativeOptionsEnabled.map((key) => {
                      const obj = relativeNamedMap[key];
                      const isActive = internalState.relativeBase === key;
                      return (
                        <button
                          key={key}
                          data-relative={key}
                          data-active={isActive ? 'true' : undefined}
                          type="button"
                          css={{
                            color: isActive ? colors.primary : undefined,
                            padding: '5px',
                            paddingLeft: 32,
                            position: 'relative',
                            borderRadius: 3,
                            whiteSpace: 'nowrap',
                            '&:hover': {
                              background: gray[95],
                            },
                            '&:before': {
                              content: `'${
                                getKeyboardFromRelative(key) ?? ''
                              }'`,
                              display: 'block',
                              width: 18,
                              height: 18,
                              backgroundImage: `url(${
                                isActive ? flagOutlineActive : flagOutlineMuted
                              })`,
                              backgroundSize: 'contain',
                              backgroundRepeat: 'no-repeat',
                              position: 'absolute',
                              left: 2,
                              top: 4,
                              paddingLeft: 5,
                              textTransform: 'uppercase',
                              fontSize: '.9em',
                              lineHeight: '18px',
                              // eslint-disable-next-line mastery/named-colors
                              color: isActive ? colors.primary : '#bbb',
                            },
                          }}
                          onClick={(): void => {
                            setRelative(key);
                            setInternalIsOpen(true);
                          }}
                        >
                          {obj.label}
                        </button>
                      );
                    })}
                  </div>
                )}
              </div>
              <Grid
                xs="50px 50px 1fr"
                css={{
                  order: 2,
                  gridColumn: '1 / span 2',
                  padding: '10px 0 0 0',
                  alignItems: 'center',
                  display: internalState.relativeBase ? 'grid' : 'none',
                  borderTop: borderStyle,
                }}
                // this helps this component work with chip filters. without this line, when user picks a relative option, the chip filter will close and the value will not be set
                onMouseDown={(e): void => e.stopPropagation()}
              >
                <Label css={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                  -
                  <Digit
                    allowEmpty
                    id="datepicker-minus"
                    value={internalState.minus || null}
                    onChange={(num): void => {
                      setInternalState((obj) => {
                        const base = obj.relativeBase;
                        const mapObj = relativeNamedMap[base as RelativeUnit];
                        const start = mapObj
                          .startFn(timezone ?? currentTZ)
                          .subtract({ days: num ?? 0 })
                          .toDate();
                        return { ...obj, minus: num ?? 0, start };
                      });
                    }}
                  />
                </Label>
                <Label css={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                  +
                  <Digit
                    allowEmpty
                    id="datepicker-plus"
                    value={internalState.plus || null}
                    onChange={(num): void => {
                      setInternalState((obj) => {
                        const base = obj.relativeBase;
                        const mapObj = relativeNamedMap[base as RelativeUnit];
                        const end = mapObj
                          .endFn(timezone ?? currentTZ)
                          .add({ days: num ?? 0 })
                          .toDate();
                        return { ...obj, plus: num ?? 0, end };
                      });
                    }}
                  />
                </Label>
                <VisuallyHidden>
                  <button type="submit" />
                </VisuallyHidden>
                <span>Days</span>
              </Grid>
            </>
          ),
        }}
      />
    </div>
  );
};
